<template>
  <div>
    <notifications group="notification" position="bottom right" />
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b card-stretch">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title">
              <span class="card-label font-weight-bolder text-dark"
                >All Repositories</span
              >
            </h3>
            <div class="card-toolbar">
              <router-link
                to="/articles/repositories/new"
                class="btn btn-primary font-weight-bolder font-size-sm"
              >
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg
                    src="media/svg/icons/Communication/Add-user.svg"
                  />
                </span>
                Add New Repository
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-head-bg table-vertical-center"
                  >
                    <thead>
                      <tr class="bg-gray-100 text-left">
                        <th style="min-width: 250px" class="pl-7">
                          Repository
                        </th>
                        <th style="min-width: 110px" class="pr-7 text-right">
                          {{ $t('GENERAL.ACTIONS') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(repository, key) in repositories" :key="key">
                        <td
                          class="pl-0 py-4"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <div class="d-flex align-items-center">
                            <div>
                              <router-link
                                :to="
                                  '/articles/repositories/edit/' + repository.id
                                "
                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                >{{ repository.name }}</router-link
                              >
                            </div>
                          </div>
                        </td>
                        <td
                          class="pr-0 text-right"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <button
                            @click="showArticles(key)"
                            class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                          >
                            <span class="svg-icon svg-icon-md"> </span>
                          </button>
                          <router-link
                            :to="'/articles/repositories/edit/' + repository.id"
                            class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/Communication/Write.svg"
                              />
                            </span>
                          </router-link>
                          <button
                            @click="removeRepository(repository.id)"
                            class="btn btn-icon btn-light btn-hover-light-primary btn-sm"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/General/Trash.svg"
                              />
                            </span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-modal id="articles_modal" centered hide-header hide-footer>
          <div class="row">
            <div class="col-12">
              <b-button
                class="btn btn-light-primary mb-3"
                block
                @click="$bvModal.hide('articles_modal')"
                >Close</b-button
              >
              <ul>
                <li v-for="(modalArticle, key) in modalArticles" :key="key">
                  <span>{{ modalArticle.name }}</span>
                  <span> {{ modalArticle.quantity }}</span>
                </li>
              </ul>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  name: 'RepositoriesList',
  data() {
    return {
      repositories: [],
      view: {
        previous: null,
        first: '?page=1',
        last: null,
        next: null,
      },
      articles: [],
      modalArticles: [],
      appPath: process.env.VUE_APP_ROUTE_API_URL,
      isLoaded: false,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Repositories', route: '/articles/repositories/list' },
    ])

    this.getRepositories('/article_stores', '').then(({ data }) => {
      this.repositories = [...data['hydra:member']]

      this.isLoaded = true
    })
  },
  methods: {
    async getRepositories(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    async deleteRepository(id) {
      try {
        return await ApiService.delete('products/' + id)
      } catch (e) {
        throw new Error(e)
      }
    },
    removeRepository(id) {
      const confirmation = confirm('You want delete this repository?')
      if (confirmation === false) return

      this.deleteRepository(id).then(({ status }) => {
        if (status === 204) {
          this.repositories = this.repositories.filter(
            (repository) => repository.id !== id
          )
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Repository success deleted from database',
          })
        }
      })
    },
    showArticles(index) {
      this.modalArticles = []
      this.modalArticles = this.repositories[index].articles

      this.$root.$emit('bv::show::modal', 'articles_modal')
    },
  },
}
</script>
